<template>
  <div class="p-1">
    <div-table
      :config="configList"
      :data="zones"
      :idWharhouse="idWharhouse"
      :currentUser="currentUser"
      @reset="reset"
      @refresh="refresh"
      @filtrer="filtrer"
      @search="search"
      :loading="loading"
      :paginate="paginate"
      @paginatation="paginatation"
    ></div-table>
  </div>
</template>

<script>
import config from "./config";
export default {
  props: {
    currentUser: { type: Object },
    idWharhouse: { type: Object },
  },
  data() {
    return {
      configList: config.list,
      loading: false,
      content: {},
      zones: [],
      // currentUser: {},
      limit: 10,
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 1,
        per_page: 0,
      },
    };
  },
  async mounted() {
    //await this.getUser();
    this.getZones({ limit: this.limit });
    this.setDataSelect("category", this.categories, null);
  },
  computed: {
    warhouseSelected() {
      return this.$store.getters["wharhouse/warhouseSelected"]({
        user: _.pick(this.currentUser, ["countries"]),
      });
    },
  },
  watch: {
    warhouseSelected: async function (oldVal, newVal) {
      this.getZones({ limit: this.limit });
    },
  },
  filters: {},
  methods: {
    setDataSelect(model, data, splice) {
      for (var i = 0; i < this.configList.filters.elements.champs.length; i++) {
        if (
          this.configList.filters.elements.champs[i].type === "select" &&
          this.configList.filters.elements.champs[i].key === model
        ) {
          if (!splice) {
            this.configList.filters.elements.champs[i].values = data;
          } else {
            this.configList.filters.elements.champs.splice(i, 1);
          }
        }
      }
    },
    async getZones(filters) {
      filters["country"] = await this.warhouseSelected;
      const res = await this.$server.search("zones", filters);
      if (res.content.results) {
        this.zones = res.content.results;
        this.zones.total = res.content.total;
        this.zones.lastpage = res.content.last_page;
        this.zones.per_page = res.content.per_page;
        this.zones.currentpage = res.content.current_page;
      } else {
        this.zones = [];
        this.paginate = { total: 0, currentpage: 1, lastpage: 1, per_page: 0 };
      }
    },

    async refresh() {
      this.getZones({ limit: this.limit });
    },

    /*********************************************************************************/
    async reset(data) {
      const filters = {
        limit: this.limit,
      };
      await this.getZones(filters);
    },
    async search(data) {
      const search = {
        name: data,
        country: await this.warhouseSelected,
      };
      const res = await this.$server.find("zones", search);

      if (res.content) {
        this.zones = res.content;
        this.zones.total = res.content.total;
        this.zones.lastpage = res.content.last_page;
        this.zones.per_page = res.content.per_page;
        this.zones.currentpage = res.content.current_page;
      } else {
        this.zones = [];
        this.paginate = { total: 0, currentpage: 1, lastpage: 1, per_page: 0 };
      }
    },
    async filtrer(data) {
      const filters = {
        name: data.name,
        cities: data.cities,
        price: data.price,
        country: data.country,
        limit: this.limit,
      };
      await this.getZones(filters);
    },
    /****************************************************************************/
    async getUser() {
      const res = await this.$server.me("users");
      if (res.content) this.currentUser = res.content;
      else this.currentUser = [];
    },
    /***************************************************************************************/
    async paginatation(direction) {
      var page = 0;
      if (direction == "next") {
        page = ++this.paginate.currentpage;
      } else {
        page = --this.paginate.currentpage;
      }
      const filters = {
        page: page,
        limit: this.limit,
      };
      this.getZones(filters);
    },
  },
};
</script>

<style></style>
